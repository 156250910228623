import { Routes, Route } from "react-router-dom";
import "./App.css";
import * as React from "react";
import "react-circular-progressbar/dist/styles.css";
import "./index.css";
import Login from "./Login";
import Home from "./Home";
import Signup from "./Signup";

import "./firebase";
import esriConfig from "@arcgis/core/config.js";
esriConfig.assetsPath = "./assets";


const App = () => {
  return (
    <div>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />
        </Route>
      </Routes>
    </div>
  );
};
export default App;
