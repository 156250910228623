import React, { useEffect, useState } from "react";
import {
  UploadOutlined,
  UserOutlined,
  LinkOutlined,
  ForwardOutlined,
  BackwardOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Layout,
  Menu,
  Button,
  theme,
  Card,
  Flex,
  Table,
  AutoComplete,
  Input,
} from "antd";
import { getAuth, signOut } from "firebase/auth";
import {
  getSheetslayers,
  addSheetToLayerItem,
  deleteSheetToLayerItem,
  useUser,
  useUserDocument,
} from "../firebase";
import { Select } from "antd";

const { Header, Sider, Content } = Layout;

const App = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, isLoadingUser] = useUser();
  const [userSnapshot, setUserDoc] = useUserDocument();
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
      } else {
        console.log("User is not logged in");
        navigate("/login");
      }
    });
    return unsubscribe;
  }, []);

  const [tableRows, setTableRows] = useState([]);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [arcUserName, setArcUserName] = useState("SkywayConsultingCo");
  const arcgisAccessToken = userSnapshot?.data?.arcgis_accessToken;
  const smartsheetToken = userSnapshot?.data?.ss_accessToken;
  const [arcgisLayers, setArcgisLayers] = useState([]);
  const [arcgisLayerOptions, setArcgisLayerOptions] = useState([]);
  const [selectedArcgisLayer, setSelectedArcgisLayer] = useState(null);
  const [isFromSheet2Arcgis, setIsFromSheet2Arcgis] = useState(true);
  const [smartsheetOptions, setSmartsheetOptions] = useState([]);
  const [smartsheetSelected, setSmartsheetSelected] = useState(null);
  console.log("🚀 ~ App ~ arcgisLayers len:", arcgisLayers?.length);

  useEffect(() => {
    const match = window.location.hash
      ? window.location.hash.match(/#access_token=([^&]+)/)
      : false;
    // if we found an access token in the URL pass the token up to a global function in
    if (match[1]) {
      setUserDoc({ arcgis_accessToken: match[1] });
      console.log("🚀 ~ useEffect ~ match[1]:", match[1]);
      const username = window.location.hash.match(/username=([^&]+)/)[1];
      if (username) {
        setArcUserName(username);
      }
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }

    const codePart = window.location.search.match(/code=([^&]+)/);
    if (codePart && codePart[1]) {
      console.log("🚀 ~ useEffect ~ codePart[1]", codePart[1]);
      const code = codePart[1];
      localStorage.setItem("smartsheet_code", code);
      getSSheetToken();
    }

    fethFirestoreData();
  }, []);

  useEffect(() => {
    if (smartsheetToken) {
      fetchSmartSheetList();
    }
  }, [smartsheetToken]);

  useEffect(() => {
    if (arcgisAccessToken) {
      fetchArcgisLayers();
    }
  }, [arcgisAccessToken]);

  const getSSheetToken = async () => {
    const url = `https://getsmartsheettoken-dk2zxl5rpa-ts.a.run.app/?code=${localStorage.getItem("smartsheet_code")}`;
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application",
      },
    });
    const data = await res.json();
    if (data && data.access_token && data.refresh_token) {
      setUserDoc({
        ss_accessToken: data.access_token,
        ss_refreshToken: data.refresh_token,
      });
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } else {
      console.log("🚀 ~ getSSheetToken ~ data", data);
      if (data?.message) {
        alert(data?.message);
      }
    }
  };

  const fethFirestoreData = async () => {
    const list = await getSheetslayers();
    const rows =
      list?.map((item, index) => {
        return {
          key: item.id,
          sheet: item.sheet,
          sheetId: item.sheetId,
          layer: item.layer,
          layerId: item.layerId,
          direction: item.direction,
          layerName: item.layerName,
        };
      }) ?? [];
    setTableRows(rows);
  };

  const fetchArcgisLayers = async () => {
    let aglayers = [];
    let nextStart = 1;
    while (nextStart > 0) {
      const url =
        `https://skywayconsulting.maps.arcgis.com/sharing/rest/search?num=200&start=${nextStart}&q=orgid%3AzlUafPRrAzvNPQpy%20type%3A%22Feature%20Service%22&f=json&token=` +
        arcgisAccessToken;
      const res = await fetch(url);
      const data = await res.json();
      console.log("🚀 ~ fetchArcgisLayers ~ data:", data);
      data?.results?.forEach((item) => {
        aglayers.push({
          title: item.title,
          url: item.url,
          id: item.id,
          value: item.title,
          id: item.id,
          label: item.title,
        });
      });
      nextStart = data.nextStart;
    }
    console.log("aglayers len=", aglayers?.length);
    setArcgisLayers(aglayers);
    setArcgisLayerOptions(aglayers);
  };

  const handleLogin = () => {
    console.log("process.env", process.env.REACT_APP_ARCGIS_CLIENT_ID);
    const clientId = process.env.REACT_APP_ARCGIS_CLIENT_ID;
    const redirectUri = window.location.origin + "/";
    window.open(
      "https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=" +
        clientId +
        "&response_type=token&expiration=20160&redirect_uri=" +
        window.encodeURIComponent(redirectUri),
      "_self"
    );
  };

  const handleSmartSheetLogin = () => {
    const clientId = process.env.REACT_APP_SMARTSHEET_CLIENT_ID;
    const url =
      "https://app.smartsheet.com/b/authorize?response_type=code&client_id=" +
      clientId +
      "&scope=READ_SHEETS%20WRITE_SHEETS&state=CA";
    console.log("🚀 ~ handleSmartSheetLogin ~ url:", url);
    window.open(url, "_self");
  };

  const toogleArrow = () => {
    setIsFromSheet2Arcgis(!isFromSheet2Arcgis);
  };
  const onMenuItemClick = (e) => {
    console.log("click", e);
    const key = e.key;
    if (key == "4") {
      signOut(auth);
    }
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchSmartSheetList = async () => {
    const url = 'https://getsmartsheetlist-dk2zxl5rpa-ts.a.run.app'
    console.log("🚀 ~ fetchSmartSheetList ~ url:", url);
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + smartsheetToken,
      },
    });
    const data = await res.json();
    console.log("🚀 ~ fetchSmartSheetList ~ data:", data);
    const { reports, sheets } = data;
    reports.forEach((item) => {
      item.type = "report";
    });
    sheets.forEach((item) => {
      item.type = "sheet";
    });
    const options = [...reports, ...sheets].map((item) => ({
      value: item.id,
      label: item.name,
      type: item.type,
    }));
    setSmartsheetOptions(options);
  };

  const start = () => {
    setLoading(true);
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };

  const onDeleteRows = async () => {
    if (selectedRowKeys.length == 0) {
      alert("Please select items to delete");
      return;
    }
    const newRows = tableRows.filter(
      (item) => !selectedRowKeys.includes(item.key)
    );
    setTableRows(newRows);
    setSelectedRowKeys([]);
    const docIds = selectedRowKeys;
    await deleteSheetToLayerItem(docIds);
  };

  const columns = [
    {
      // title: "Smartsheet Tables" + (tableRows.length? (`${"          "}` + 'Rows:' + tableRows.length) : ""),
      title: tableRows.length
        ? `Smartsheet Tables (${tableRows.length})`
        : "Smartsheet Tables",
      dataIndex: "sheet",
    },
    {
      title: "ArcGIS Layers",
      dataIndex: "layer",
    },
  ];

  const data = [];
  for (let i = 0; i < 46; i++) {
    data.push({
      key: i,
      from: `Sheet ${i}`,
      to: `Layer ${i}`,
    });
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const onSelect = (value, option) => {
    // console.log('onSelect', value, option);
    const selected = arcgisLayerOptions.find((item) => item.id == option.id);
    setSelectedArcgisLayer(selected);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const onAddItem = async () => {
    if (!smartsheetSelected || !selectedArcgisLayer) {
      alert("Please select smartsheet and arcgis layer");
      return;
    }
    const newRow = {
      key: tableRows.length,
      sheet: smartsheetSelected.label,
      sheetId: smartsheetSelected.value,
      ssType: smartsheetSelected.type,
      direction: "Yes",
      layer: selectedArcgisLayer.label,
      layerId: selectedArcgisLayer.id ?? null,
    };
    console.log("🚀 ~ onAddItem ~ newRow:", newRow);
    await addSheetToLayerItem(newRow);
    setTableRows([...tableRows, newRow]);
  };

  const handleSearch = (value) => {
    setArcgisLayerOptions(
      arcgisLayers.filter((item) => item.label.includes(value))
    );
  };

  const onArcLayersRefresh = () => {
    fetchArcgisLayers();
  };

  const hasSelected = selectedRowKeys.length > 0;
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider trigger={null} collapsible breakpoint={"lg"}>
        <div className="demo-logo-vertical" />

        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          onClick={onMenuItemClick}
          items={[
            {
              key: "1",
              icon: <LinkOutlined />,
              label: "Connections",
            },
            {
              key: "2",
              icon: <UploadOutlined />,
              label: "Credit/Payment Dashboard?",
            },
            {
              key: "3",
              icon: <PhoneOutlined />,
              label: "Contact",
            },
            {
              key: "uid",
              icon: <UserOutlined />,
              label: isLoadingUser ? "Loading..." : user ? user.uid : "Not Logged In",
              disabled: true,
            },
            {
              key: "4",
              icon: <UserOutlined />,
              label: "Logout",
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "contents",
            }}
          >
            <img src="/logo.png" alt="logo" style={{}} />
          </div>
        </Header>

        <style>
          {`
          @media (min-width: 768px) {
            .site-layout-background {
              margin: 24px 16px;
              padding: 24;
            }
          }
          `}
        </style>
        <Content
          style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
          className={"site-layout-background"}
        >
          <Row style={{ marginTop: 20 }}>
            <Card bordered={false} className="blindsCard">
              <Row>
                <Col className="blindsList" xs={24} md={10} xl={10}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginRight: 5,
                    }}
                  >
                    <img
                      src="/smartsheet_log.png"
                      width={15}
                      height={15}
                      style={{ marginRight: 5 }}
                    ></img>
                    <h4 style={{ color: "#1677ff" }}>smartsheet</h4>
                  </div>

                  <Button type="primary" onClick={handleSmartSheetLogin} style={{ whiteSpace: "normal", height: "auto" }}>
                    Login with Smartsheet
                  </Button>
                  {smartsheetToken && (
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select Sheet or Report"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "").includes(input)
                      }
                      filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                          .toLowerCase()
                          .localeCompare((optionB?.label ?? "").toLowerCase())
                      }
                      options={smartsheetOptions}
                      onChange={(value) => {
                        const selected = smartsheetOptions.find(
                          (item) => item.value == value
                        );
                        setSmartsheetSelected(selected);
                      }}
                    />
                  )}
                </Col>
                <Col className="blindsList" xs={24} md={4} xl={4}>
                  <h4> </h4>
                  {isFromSheet2Arcgis ? (
                    <ForwardOutlined
                      style={{ fontSize: "50px", color: "#08c" }}
                    />
                  ) : (
                    <BackwardOutlined
                      onClick={toogleArrow}
                      style={{ fontSize: "50px", color: "#08c" }}
                    />
                  )}
                </Col>
                <Col className="blindsList" xs={24} md={10} xl={10}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/output-onlinepngtools.png"
                      width={15}
                      height={15}
                      style={{ marginRight: 5 }}
                    ></img>
                    <h4>ArcGIS</h4>
                  </div>

                  <Button type="primary" onClick={handleLogin} style={{ whiteSpace: "normal", height: "auto" }}>
                    Login with ArcGIS Online
                  </Button>
                  {arcgisAccessToken && (
                    <Flex justify="center" align="center">
                      <AutoComplete
                        popupMatchSelectWidth={252}
                        style={{ width: 300 }}
                        options={arcgisLayerOptions}
                        onSelect={onSelect}
                        onSearch={handleSearch}
                        onChange={(value) => {
                          console.log("onChange", value);
                          setSelectedArcgisLayer({
                            label: value,
                            value: value,
                            title: value,
                          });
                        }}
                        size="large"
                      >
                        <Input.Search
                          size="large"
                          placeholder="Type Layer Name or Select New Layer"
                          enterButton
                        />
                      </AutoComplete>
                      <Button
                        onClick={onArcLayersRefresh}
                        type="primary"
                        className="ml-2"
                      >
                        Refresh
                      </Button>
                    </Flex>
                  )}
                </Col>
              </Row>
              <Row justify={"center"} style={{ marginTop: 10 }}>
                <Button onClick={onAddItem} type="primary">
                  + Connection
                </Button>
              </Row>
            </Card>
          </Row>

          <div>
            <div style={{ marginTop: 16, marginBottom: 16 }}>
              <Row justify={"space-between"}>
                <Button
                  type="primary"
                  onClick={start}
                  disabled={!hasSelected}
                  loading={loading}
                >
                  Reload
                </Button>
                <Button type="primary" onClick={onDeleteRows} loading={loading}>
                  Delete Selected Items
                </Button>
              </Row>

              <span style={{ marginLeft: 8 }}>
                {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
              </span>
            </div>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={tableRows}
            />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
export default App;
