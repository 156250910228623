import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc, setDoc, doc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {useEffect, useMemo, useState} from "react";

const firebaseConfig = {
  apiKey: "AIzaSyA1cxqrVfx2yll-Qz7ZvZgHgiVE46hx4Ig",
  authDomain: "smartgis-332de.firebaseapp.com",
  projectId: "smartgis-332de",
  storageBucket: "smartgis-332de.appspot.com",
  messagingSenderId: "540221414784",
  appId: "1:540221414784:web:94053808a34a2463ee7565",
  measurementId: "G-5ZWHSZT2E4"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);


export const getSheetslayers = async () => {
  const uid = localStorage.getItem('fb_uid');
  if (!uid) {
    console.error('User not logged in!');
    return;
  }
  const col = collection(db, `users/${uid}/sheetslayers`);
  const snapshot = await getDocs(col);
  const sheetslayers = snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  }));
  return sheetslayers;
}

export const addSheetToLayerItem = async (item) => {
  const uid = localStorage.getItem('fb_uid');
  if (!uid) {
    console.error('User not logged in!');
    return;
  }
  try {
    const collectionRef = collection(db, `users/${uid}/sheetslayers`);
    // console.log("🚀 ~ addSheetToLayerItem ~ newCollectionRef:", collectionRef)
    const docRef = await addDoc(collectionRef, item);
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error('Error adding document:', error);
  }
}

export const deleteSheetToLayerItem = async (docIds) => {
  const uid = localStorage.getItem('fb_uid');
  if (!uid) {
    console.error('User not logged in!');
    return;
  }
  try {
    docIds.forEach(async (docId) => {
      await deleteDoc(doc(db, `users/${uid}/sheetslayers`, docId));
    });
  } catch (error) {
    console.error('Error adding document:', error);    
  }
}

export const useUser = () => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setIsLoading(false);
    });
    return () => unsubscribe();
  }, []);

  return [user, isLoading];
};

/**
 * @param {Object} obj data of firestore document
 * @returns Object with all Timestamp objects converted to Date objects, in-place
 */
export default function convertTimestamps(obj) {
  if (obj instanceof Object) {
    for (const key in obj) {
      if (obj[key]?.toDate instanceof Function) {
        obj[key] = obj[key].toDate();
      } else if (obj[key] instanceof Object) {
        convertTimestamps(obj[key]);
      }
    }
  }
  return obj;
}

/**
 * @template S
 * @param {string} collectionName The collection in firestore you want to access
 * @param {string | null} default_id The id of the document in the collection
 * @param {boolean} merge Whether to merge the new data with the old data or replace it
 * @param {boolean} returnTimestamps Whether to return createdAt and updatedAt fields
 * @returns {[{exists: boolean, id: string, path: string, data: S}, Dispatch<SetStateAction<S>>, () => Promise<void>, boolean]} [snapshot, updateData, deleteDocument, isLoading]
 *
 * Returns a firebase-document-like snapshot, a function to update the document data, a function to remove the document, and a boolean indicating whether the document is loading.
 */
const useDocument = (collectionName, default_id, {merge = true, returnTimestamps = false} = {}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [id, setID]               = useState(default_id);
  const [snapshot, setSnapshot]   = useState({exists: false, id, path: `${collectionName}/${id}`, data: null});

  const docRef         = useMemo(
    () => (id ? doc(db, collectionName, id) : null),
    [collectionName, id]
  );
  const updateData = useMemo(
    () => async (nextStateValue) => {
      setIsLoading(true);
      const createdAt = snapshot.exists ? {} : {createdAt: serverTimestamp()};
      const updatedAt = serverTimestamp();
      if (docRef) {
        await setDoc(docRef, {...nextStateValue, ...createdAt, updatedAt}, {merge});
        setIsLoading(false);
      } else {
        const newDocRef =
                await addDoc(collection(db, collectionName), {...nextStateValue, ...createdAt, updatedAt});
        setID(newDocRef.id);
        setIsLoading(false);
      }
    },
    [docRef, collectionName, snapshot, merge]
  );
  const deleteDocument = useMemo(
    () => async () => {
      setIsLoading(true);
      await deleteDoc(docRef);
      setID(null);
      setIsLoading(false);
    },
    [docRef]
  );

  useEffect(() => {
    setID(default_id);
  }, [default_id]);

  useEffect(() => {
    if (docRef) {
      setIsLoading(true);
      return onSnapshot(docRef, (nextDocumentSnapshot) => {
        if (nextDocumentSnapshot.exists()) {
          const {createdAt, updatedAt, ...data} = nextDocumentSnapshot.data();
          const dataToReturn                    = returnTimestamps ? {createdAt, updatedAt, ...data} : data;
          setSnapshot({
            exists: true,
            id:     nextDocumentSnapshot.id,
            path:   nextDocumentSnapshot.ref.path,
            data:   convertTimestamps(dataToReturn),
          });
        } else {
          setSnapshot({exists: false, id: docRef.id, path: docRef.path, data: null});
        }
        setIsLoading(false);
      });
    } else {
      setSnapshot(snapshot => ({...snapshot, exists: false, id: null, data: null}));
    }
  }, [docRef, returnTimestamps]);
  return [snapshot, updateData, deleteDocument, isLoading];
};

export const useUserDocument = () => {
  const [user, isLoadingUser] = useUser();
  const [snapshot, updateData, deleteDocument, isLoadingDocument] = useDocument('users', user?.uid);
  return [snapshot, updateData, deleteDocument, isLoadingUser || isLoadingDocument];
};
